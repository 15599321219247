import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


import { Spacer, Link } from '@nextui-org/react';
import { Layout, Row, Avatar, Button, Image, Tooltip } from 'antd';
import { Container } from "react-bootstrap";
// import { DownOutlined } from '@ant-design/icons';
// import type { MenuProps } from 'antd';
// import { Dropdown, Space } from 'antd';

import walia_app_logo from "../assets/walia-app-logo.png";
import walia_logo from "../assets/walia-logo.png";
import { DashboardIcon } from '../components/Icons/DashboardIcon';
import { StructureIcon } from '../components/Icons/StructureIcon';
import { StructureLevelIcon } from '../components/Icons/StructureLevelIcon';
import style from "./InternalLayout.module.css";
import { EmployeeIcon } from '../components/Icons/EmployeeIcon';
import { HistoryIcon } from '../components/Icons/HistoryIcon';
import { ReportIcon } from '../components/Icons/ReportIcon';
import * as myConstant from "../GlobalVariable";
import { getUserType } from "../jwtDecoder";

// import { Menu, Input, Avatar, Badge } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
// const { SubMenu } = Menu;
// const { Search } = Input;



function InternalLayout({ children, title }: { children: any, title: string }) {

    const [userType, setUserType] = useState<string | null>();
    const [authenticated, setAuthenticated] = useState(false);
    const [profileData, setProfileData] = useState({
        profilePic: "",
        fullName: "",
        loaded: false,
        loading: false,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (
            authenticated === true ||
            sessionStorage.getItem("Token") !== null ||
            localStorage.getItem("Token") !== null
        ) {
            setAuthenticated(true);
        } else {
            navigate("/login");
        }

    }, []);

    // useEffect(() => {
    //     if (authenticated) {
    //         console.log("testing");
    //     }
    // }, [authenticated]);

    useEffect(() => {
        const jwtToken: string = localStorage.getItem("Token")!;
        const incomingType = getUserType(jwtToken)
        setUserType(incomingType);
    }, []);

    useEffect(() => {
        if (authenticated === true) {
            setProfileData({ ...profileData, loading: true });
            axios.get(myConstant.BASE_URL + "account/fullname/",
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " +
                            (sessionStorage.getItem("Token") !== null
                                ? sessionStorage.getItem("Token")
                                : localStorage.getItem("Token")),
                    },
                    withCredentials: true,
                }
            ).then(function (response) {
                if (response.status === 200) {
                    const apiData = response.data.result;

                    setProfileData({
                        profilePic: apiData["profilePic"],
                        fullName: apiData["fullname"],
                        loaded: true,
                        loading: false,
                    })
                }
            }).catch(function (error) {

                if (error !== undefined && error.response !== undefined) {
                    console.log("error", error);
                    if (error.response.status !== undefined && error.response.status === 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.reload();
                    }
                    else if (error.response.data !== undefined && error.response.data.message !== undefined) {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                    }
                    // alert(error.response.data.error);
                }
            });
        }
    }, [authenticated])
    // Your component's state
    const [collapsed, setCollapsed] = useState(false);

    // Function to toggle collapse state
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => setIsOpen(!isOpen);

    // const items: MenuProps['items'] = [
    //     {
    //         label: <p className={style["link-name"]}>Structure Level</p>,
    //         key: '0',
    //     },
    //     {
    //         label: <p className={style["link-name"]}>Structure</p>,
    //         key: '1',
    //     },
    // ];

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        if (authenticated === true) {
            // console.log("rest")
            axios
                .post(myConstant.BASE_URL + "session/logout/",
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                )
                .catch(function (error) {
                    console.log(error);
                    if (error.response !== null && error.response.data !== null && error.response.data.error !== null) {
                        alert(error.response.data.error);
                    } else {
                        alert(error);
                    }
                });
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
            setAuthenticated(false);
        }
    }

    return (
        <div>
            <Layout >

                <Sider
                    className={style["side-bar"]}
                    width={255}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                >
                    <Spacer y={5}></Spacer>
                    {!collapsed ?
                        <img
                            src={walia_app_logo}
                            style={{
                                padding: "0em 2em 2em 2em",
                                position: "fixed",
                                width: "260px"
                            }}
                            alt="lucy"
                        /> :
                        <img
                            src={walia_logo}
                            style={{
                                padding: "0em 1em 1em 1em",
                                position: "fixed",
                                width: "80px"
                            }}
                            alt="lucy"
                        />
                    }


                    <Spacer y={1}></Spacer>
                    <Container style={{
                        position: "fixed",
                        top: "120px",
                    }}  >
                        {/* start - dashboard link */}
                        <Container
                            className={style["page-link-container"]}
                        >
                            <Row align={"middle"} justify={"start"}>
                                <Link href="/"
                                    className={style["link-btn"]}
                                >
                                    <DashboardIcon color='#1E4A62' />
                                    {!collapsed ? <p className={style["link-name"]}>Dashboard</p> : null}

                                </Link>
                            </Row>
                        </Container>
                        {/* end - dashboard link */}


                        {/* start structure Level links */}
                        <Container className={style["page-link-container"]}>
                            <Row align={"middle"} justify={"start"}>
                                <Link href="/structure/level"
                                    className={style["link-btn"]}
                                >
                                    <StructureIcon color='#1E4A62' />
                                    {!collapsed ? <p className={style["link-name"]}>Structure Level</p> : null}

                                </Link>
                            </Row>
                        </Container>
                        {/* end - structure links */}


                        {/* start structure links */}
                        <Container className={style["page-link-container"]}>
                            <Row align={"middle"} justify={"start"}>
                                <Link href="/structure"
                                    className={style["link-btn"]}
                                >
                                    <StructureLevelIcon color='#1E4A62' />
                                    {!collapsed ? <p className={style["link-name"]}>Structure</p> : null}
                                </Link>
                            </Row>
                        </Container>
                        {/* end - structure links */}


                        {/* start - employee link */}
                        <Container className={style["page-link-container"]}>
                            <Row align={"middle"} justify={"start"}>
                                <Link
                                    href='/employee'
                                    className={style["link-btn"]}
                                >
                                    <EmployeeIcon color='#1E4A62' />
                                    {!collapsed ? <p className={style["link-name"]}>Employee</p> : null}

                                </Link>
                            </Row>
                        </Container>
                        {/* end - employee link */}

                        {/* start - report link */}
                        <Container className={style["page-link-container"]}>
                            <Row style={{ width: "100%" }} align={"middle"} justify={"start"}>
                                <Link
                                    href='/report'
                                    className={style["link-btn"]}
                                >
                                    <ReportIcon color='#1E4A62' />
                                    {!collapsed ? <p className={style["link-name"]}>Report</p> : null}

                                </Link>
                            </Row>
                        </Container>
                        {/* end - report link */}

                        {/* start - history link */}
                        {["Owner", "SuperAdmin", "Admin"].indexOf(userType!) >= 0 ? <Container className={style["page-link-container"]}>
                            <Row align={"middle"} justify={"start"}>
                                <Tooltip title="Feature coming in version 2">
                                    <Link
                                        className={style["link-btn"]}
                                    >
                                        <HistoryIcon color='#1E4A62' />
                                        {!collapsed ? <p className={style["link-name"]}>History</p> : null}

                                    </Link>
                                </Tooltip>
                            </Row>
                        </Container> : <></>
                        }
                        {/* end - history link */}
                    </Container>
                </Sider>

                <Layout>
                    <Header className={style["header-bar"]}
                    >
                        {/* Menu icon */}
                        {collapsed ? (
                            <MenuUnfoldOutlined onClick={toggleCollapse} />
                        ) : (
                            <MenuFoldOutlined onClick={toggleCollapse} />
                        )}

                        {/* Search bar */}
                        {/* <Search
                            placeholder="Search..."
                            prefix={<SearchOutlined />}
                            style={{ width: 200, marginLeft: 'auto', marginRight: 16, paddingTop: 16 }}
                        /> */}

                        {/* Right-side icons */}
                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>

                            {/* Notification icon */}
                            {/* <Badge dot>
                                <BellOutlined style={{ fontSize: 18, marginRight: 16 }} />
                            </Badge> */}

                            {/* User profile */}
                            {/* <Menu mode="horizontal"> */}
                            {/* <SubMenu
                                    key="userMenu"
                                    title={
                                        <Row>
                                            <Col>
                                                <span className="submenu-title-wrapper">
                                                    <Avatar size={25} icon={<Image
                                                        src={`data:image/png;base64,${profileData.profilePic}`}
                                                        alt="Base64 Encoded Image"
                                                    />} />
                                                </span>
                                                <span>profileData.fullNam</span>
                                            </Col>
                                        </Row>
                                    }
                                >
                                </SubMenu> */}
                            <span className="submenu-title-wrapper">
                                <Avatar size={25} icon={
                                <Image
                                    src={`data:image/png;base64,${profileData.profilePic}`}
                                    alt="Base64 Encoded Image"
                                />
                                } />
                            </span>
                            <Spacer x={1}></Spacer>
                            <span>{profileData.fullName}</span>
                            {/* </Menu> */}
                            <Spacer x={2}></Spacer>
                            <Button
                                onClick={(e) => handleSubmitClick(e)}
                            >
                                Sign out
                            </Button>

                        </div>
                    </Header>

                    <Content className='p-4 m-2'>
                        <p className={style["page_title"]}
                        >{title}</p>
                        {children}
                    </Content>
                </Layout>

                {/* <Layout>
                    <Header
                        className={style["header-bar"]}
                    >
                        Header
                    </Header>
                    <Content className='p-4 m-2'>
                        <p>
                            {title}
                        </p>
                        {children}
                    </Content>
                </Layout> */}


            </Layout>
        </div>
    )
}

export default InternalLayout