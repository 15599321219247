export const BASE_URL = process.env.REACT_APP_API_URL;

export interface StructureLevelDataType {
  key: React.Key;
  id: string;
  name: string;
  title: string;
  parent: string;
  description: string;
}

export interface StructureDataType {
  key: React.Key;
  isEditable: string;
  id: string;
  name: string;
  title: string;
  head: string;
  description: string;
  childStructures: string;
  parentStructure: string;
  structureLevel: string;
}

export interface EmployeeDataType {
  key: React.Key;
  id: string;
  isViewer: boolean;
  title: string;
  username: string;
  prefix: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  // phoneNumber: string;
  createdOn: string;
  belongsTo: string;
  profilePic: string;
  status: string;
  otpSent: boolean;
  type: string;
  editEmail: boolean;
  editUsername: boolean;
}

export interface EmployeesPerformanceDataType {
  key: React.Key;
  id: string;
  title: string;
  fullName: string;
  email: string;
  belongsTo: string;
  profilePic: string;
  totalCount: number;
  assignedCount: number;
  canceledCount: number;
  completedCount: number;
  inProgressCount: number;
  pendingCount: number;
  redoCount: number;
  verifiedCount: number;
  tasks: Array<TaskPerformanceDataType>;
}

export interface StructurePerformanceDataType {
  key: React.Key;
  id: string;
  name: string;
  supervisor: string;
  totalCount: number;
  assignedCount: number;
  canceledCount: number;
  completedCount: number;
  inProgressCount: number;
  pendingCount: number;
  redoCount: number;
  verifiedCount: number;
  tasks: Array<TaskPerformanceDataType>;
}

export interface OneEntityPerformanceDataType {
  key: React.Key;
  id: string;
  title: string;
  priority: string;
  progress: string;
  status: string;
}

export interface TaskPerformanceDataType {
  key: React.Key;
  id: string;
  title: string;
  memberCount: string;
  status: string;
  priority: string;
  startDate: string;
  dueDate: string;
  assignedTo: Array<Map<string, any>>;
}
